import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import ImageCarousel from 'src/Component/ImageCarousel'
import AppIcon from 'src/assets/images/AppIcon.png'
import AskEmailAndSendOTP from './AskEmailAndSendOTP'
import VerifyOTP from './VerifyOTP'
import ResetPassword from './ResetPassword'
import PasswordChangedSuccessfully from './PasswordChangedSuccessfully'
import { useDispatch } from 'react-redux'
import { setErrorReducer } from 'src/store/features/forgotPassword/forgotPasswordSlice'

const ForgotPassword = () => {
  const [step, setStep] = useState<number>(0)
  const dispatch = useDispatch()

  const sendOtpCallback = () => {
    console.log('sendOtpCallback')
    setStep((prev) => prev + 1)
  }

  const verifyOtpCallback = () => {
    console.log('verifyOtpCallback')
    setStep((prev) => prev + 1)
  }

  const resetPasswordCallback = () => {
    console.log('resetPasswordCallback')
    setStep((prev) => prev + 1)
  }

  const GetActiveStepComponent = () => {
    switch (step) {
      case 0:
        return <AskEmailAndSendOTP callback={sendOtpCallback} />
      case 1:
        return <VerifyOTP callback={verifyOtpCallback} />
      case 2:
        return <ResetPassword callback={resetPasswordCallback} />
      case 3:
        return <PasswordChangedSuccessfully />
      default:
        return null
    }
  }

  useEffect(() => {
    dispatch(setErrorReducer(''))
  }, [])
  return (
    <Grid container direction='row' justifyContent='center' alignItems='center' height='100vh'>
      <Grid item xs={12} sm={12} md={6}>
        <ImageCarousel />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Grid container direction='column' justifyContent='center' alignItems='center'>
          <img src={AppIcon} alt='Icon' height={'150px'} width='169.85px' />
          <GetActiveStepComponent />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ForgotPassword
