import * as React from 'react';

const TermsAndCondition = (props) => (
  <svg
    fill='#909090'
    id='Capa_1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 297 297'
    xmlSpace='preserve'
    width='18px'
    height='18px'
    stroke='#909090'
    strokeWidth={0.00297}
    {...props}
  >
    <g id='SVGRepo_bgCarrier' strokeWidth={0} />
    <g
      id='SVGRepo_tracerCarrier'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <g id='SVGRepo_iconCarrier'>
      <g>
        <path d='M218.51,0H56.833C44.366,0,33.5,10.762,33.5,23.5v251c0,12.617,10.435,22.5,23.333,22.5h184 c12.897,0,22.667-9.883,22.667-22.5V45.882L218.51,0z M240.833,281h-184c-3.99,0-7.333-2.794-7.333-6.5v-251 c0-3.995,3.604-7.5,7.333-7.5H206.5v41h41v217.5C247.5,278.206,244.823,281,240.833,281z' />
        <rect x={66.5} y={50} width={82} height={16} />
        <rect x={66.5} y={83} width={165} height={16} />
        <path d='M148.833,116.4c-20.276,34.022-57.788,24.349-57.788,24.349c0,67.714-1.859,75.386,13.857,90.896 c12.672,12.508,43.932,33.357,43.932,33.357s31.26-20.849,43.933-33.357c15.715-15.51,13.855-23.182,13.855-90.896 C206.621,140.749,169.109,150.423,148.833,116.4z M179.581,219.686c-6.325,6.242-19.289,15.568-26.454,20.572l-4.42,2.999 l-0.206-0.057V191h-39.67c-0.146-6-0.123-13.852-0.094-23.344l0.031-8.964l7.118-0.371c10.921-0.521,20.163-4.243,27.797-11.032 l4.817-4.447V191h40.336c-0.145,6-0.453,10.564-1.086,14.176C186.682,211.267,184.773,214.561,179.581,219.686z' />
      </g>
    </g>
  </svg>
);

export default TermsAndCondition;