import { Box, Dialog, Slider, Typography } from '@mui/material';
import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import eyeCloseIcon from 'src/assets/icons/eye-close.png';
import brokenImage from 'src/assets/images/broken-image.png';
import styles from './index.module.scss';
type ImageCarouselProps = {
  activeImageIndex: number;
  images: { id: number; image: string; annotations?: string, type?:'video' | 'image' }[];
  isPhotoBlur: boolean;
};
const ImageCarousel = ({ activeImageIndex, images, isPhotoBlur }: ImageCarouselProps) => {
  const [open, setOpen] = React.useState(false);
  const [activeImage, setActiveImage] = useState('');

  const [zoomLevel, setZoomLevel] = useState(100);

  const handleZoomChange = (event, newValue) => {
    setZoomLevel(newValue);
  };

  const handleClickOpen = (image: string) => {
    setActiveImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <Carousel
          className={styles.carousel}
          navButtonsAlwaysInvisible
          autoPlay={false}
          cycleNavigation={true}
          swipe={true}
          indicators={false}
          animation="fade"
          fullHeightHover={false}
          changeOnFirstRender
          // activeIndicatorIconButtonProps={{
          //   style: {
          //     color: '#34D695',
          //   },
          // }}
          height="350px"
          //   indicatorContainerProps={{
          //     style: {
          //       margin: '0px',
          //       padding: '0px',
          //     },
          //   }}
          index={activeImageIndex}
        >
          {images.map(image => {
            return (
              <Box
                key={image.id}
                sx={{
                  height: '100%',
                }}
                className="annotation-tooltip-wrapper"
              >
                {image.type == 'video' ? (
                  <video controls autoPlay={false} loop={false} muted={true} style={{ objectFit: 'contain', height: '100%', borderRadius: '10px' }}>
                    <source
                      src={image.image}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <>
                    <img
                      onClick={() => handleClickOpen(image.image)}
                      src={image.image}
                      style={{
                        height: '95%',
                        // width: '100%',
                        objectFit: 'cover',
                        borderRadius: '10px',
                        cursor: 'pointer',
                      }}
                      onError={evt => {
                        evt.currentTarget.src = brokenImage;
                      }}
                      className={isPhotoBlur ? styles.blurImage : ''}
                    />
                    {image.annotations && <div className="annotation-tooltiptext">{image.annotations}</div>}
                  </>
                )}
              </Box>
            );
          })}
        </Carousel>

        {isPhotoBlur && (
          <Box className={styles.blurImageText}>
            <Typography variant="h5">
              <img src={eyeCloseIcon} />
            </Typography>
            <Typography variant="h5">Graphic content</Typography>
            <Typography variant="subtitle1">
              This image contains content <br /> that some people may find
              <br /> upsetting
            </Typography>
          </Box>
        )}
      </Box>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <Box className={styles.imageContainer} onDoubleClick={() => setZoomLevel(100)}>
          <img
            src={activeImage}
            className={styles.zoomableImage}
            style={{
              transform: `scale(${zoomLevel / 100})`,
              cursor: zoomLevel > 100 ? 'grab' : 'default',
            }}
          />
        </Box>
        <Box className={styles.sliderContainer}>
          <Slider
            value={zoomLevel}
            onChange={handleZoomChange}
            aria-labelledby="zoom-slider"
            min={1}
            max={200}
            valueLabelDisplay="auto"
          />
        </Box>
      </Dialog>
    </>
  );
};

export default ImageCarousel;
