import React, { useState } from 'react'
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material'
import {
  useAddAnonymousCommentMutation,
  useAddCommentMutation,
  useListCommentsQuery,
  usePostViewQuery,
} from 'src/api/feed'
import CommentList from './CommentList'
import CommentBox from './CommentBox'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, AppDispatch } from 'src/store'
import { chatOrCommentMessageType, userRoles } from 'src/utils/constants'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import { FocusArea, Specialty } from 'src/api/feed/types'
import ExpandablePostDescription from './ExpandablePostDescription'
import LongDescriptionView from './LongDescriptionView'
import authorizationCheckHook from 'src/utils/setup/authorizationCheckHook'
import InputField from 'src/Component/InputField'
import AuthModal from '../AuthModal'

type ExpandViewProps = {
  postId: number
  description: string
  tags: {
    id: number
    name: string
  }[]
  specialty?: Specialty[]
  focusArea?: FocusArea[]
  isLongDescription: boolean
  hashid?: string
  sections: Array<any>
}
const ExpandView = ({
  description,
  tags,
  postId,
  specialty,
  focusArea,
  sections,
  isLongDescription,
  hashid,
}: ExpandViewProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const { isUnAuthorizedUser } = authorizationCheckHook()
  const isUserUnAuthorized = isUnAuthorizedUser()

  const { role } = useSelector((state: RootState) => state.auth)

  const [addComment, { isLoading: isAddCommentLoading }] = useAddCommentMutation()
  const [addAnonymousComment] = useAddAnonymousCommentMutation()
  const [comment, setComment] = useState<string>('')
  const [isAnonymous, setIsAnonymous] = useState<boolean>(false)

  const [anonymousUser, setAnonymousUser] = useState({
    fName: '',
    lName: '',
  })

  const [open, setOpen] = useState(false)
  const [authModalOpen, setAuthModalOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const {
    isLoading: isCommentsLoading,
    data: commentData,
    refetch,
    isFetching,
  } = isUserUnAuthorized ? usePostViewQuery(hashid) : useListCommentsQuery(postId)

  const handleSpeechToTextResponse = (value: string) => {
    setComment(value)
  }

  const handleAddComment = () => {
    if (!comment) return

    if (isUserUnAuthorized) {
      handleClickOpen()
    } else {
      const formData = new FormData()
      formData.append('text', comment)
      formData.append('comment_type', chatOrCommentMessageType.text)
      addCommentApi(formData)
    }
  }

  const handleInputChange =
    (field: 'fName' | 'lName') => (e: React.ChangeEvent<HTMLInputElement>) => {
      setAnonymousUser({
        ...anonymousUser,
        [field]: e.target.value,
      })
    }

  const handleAddVoiceMemo = (file: File, duration: number) => {
    if (file) {
      const formData = new FormData()
      formData.append('audio', file)
      formData.append('comment_type', chatOrCommentMessageType.audio)
      formData.append('audio_duration', duration.toString())
      addCommentApi(formData)
    }
  }

  const handleSendImage = (file: File) => {
    if (file) {
      const formData = new FormData()
      formData.append('image', file)
      formData.append('comment_type', chatOrCommentMessageType.image)
      addCommentApi(formData)
    }
  }

  const addCommentApi = (formData: FormData) => {
    formData.append('is_anonymous', isAnonymous ? 'true' : 'false')
    addComment({
      postId: postId,
      payload: formData,
    })
      .unwrap()
      .then((res) => {
        setComment('')
        refetch()
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  const addAnonymousCommentApi = () => {
    handleClose()
    addAnonymousComment({
      hashId: hashid,
      payload: {
        text: comment,
        firstname: anonymousUser.fName,
        lastname: anonymousUser.lName,
      },
    })
      .unwrap()
      .then((res) => {
        setComment('')
        setAnonymousUser({
          fName: '',
          lName: '',
        })
        refetch()

        setTimeout(() => {
          setAuthModalOpen(true)
        }, 2000)
      })
      .catch((err) => {
        setComment('')
        setAnonymousUser({
          fName: '',
          lName: '',
        })
        console.log('err', err)
      })
  }

  if (isFetching) {
    dispatch(startPageLoader())
  } else {
    dispatch(stopPageLoader())
  }

  const renderTags = (title: string, data?: { id: any; name: string }[]) => {
    return (
      <Box display='flex' alignItems='center'>
        {data && data.length > 0 && (
          <>
            <Typography variant='subtitle1'>
              <span style={{ marginRight: '8px', fontWeight: 'bold' }}>{title}:</span>
              <span style={{ fontWeight: 600 }}>
                {data.map((item) => (
                  <a key={item.id}> #{item.name} </a>
                ))}
              </span>
            </Typography>
          </>
        )}
      </Box>
    )
  }

  return (
    <Box>
      <Box mt={2} mb={2}>
        {isLongDescription ? (
          <LongDescriptionView content={sections} />
        ) : (
          <ExpandablePostDescription text={description} maxLength={500} />
        )}
      </Box>
      <Box sx={{ marginBottom: 3 }}>
        {renderTags('Tags', tags)}

        {renderTags('Subspecialty', specialty)}

        {renderTags('Focus Area', focusArea)}
      </Box>

      <CommentList data={commentData} isLoading={isCommentsLoading} refetch={refetch} />

      {(role === userRoles.healthCare || isUserUnAuthorized) && (
        <CommentBox
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value)}
          isLoading={isAddCommentLoading}
          handleAddComment={handleAddComment}
          handleAddVoiceMemo={handleAddVoiceMemo}
          comment={comment}
          placeholder='Add a comment'
          onSpeechToTextResponse={handleSpeechToTextResponse}
          handleImageUpload={handleSendImage}
          isAnonymous={isAnonymous}
          handleIsAnonymous={(event: React.ChangeEvent<HTMLInputElement>) =>
            setIsAnonymous(event.target.checked)
          }
        />
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault()
            addAnonymousCommentApi()
          },
        }}
      >
        <DialogTitle>
          <Typography variant={'h4'}>Sapi3nt Health</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant={'subtitle1'}>Kindly provide your first name and last name to add your comment.</Typography>
          </DialogContentText>

          <InputField
            margin='dense'
            name='first_name'
            id='first_name'
            type='text'
            placeholder='First Name'
            value={anonymousUser.fName}
            onChange={handleInputChange('fName')}
            required
          />

          <InputField
            margin='dense'
            name='last_name'
            id='last_name'
            type='text'
            placeholder='Last Name'
            value={anonymousUser.lName}
            onChange={handleInputChange('lName')}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button type='submit'>Add Comment</Button>
        </DialogActions>
      </Dialog>

      <AuthModal isOpen={authModalOpen} />
    </Box>
  )
}

export default ExpandView
