import React, { useEffect, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'
import CommentItem from './CommentItem'
import CommentBox from './CommentBox'
import {
  useAddCommentReplyMutation,
  useListCommentRepliesQuery,
  useListCommentsQuery,
} from 'src/api/feed'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, AppDispatch } from 'src/store'
import { chatOrCommentMessageType, userRoles } from 'src/utils/constants'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import authorizationCheckHook from 'src/utils/setup/authorizationCheckHook'

type ReplyContainerProps = {
  commentId: number
  postId: number
}
const ReplyContainer = ({ commentId, postId }: ReplyContainerProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const { isUnAuthorizedUser } = authorizationCheckHook()
  const isUserUnAuthorized = isUnAuthorizedUser()

  const { role } = useSelector((state: RootState) => state.auth)
  const {
    isLoading: isCommentsLoading,
    refetch: refetchReplies,
    data: commentData,
  } = useListCommentsQuery(postId)
  const {
    isLoading: isFetchingReplies,
    data: commentReplies,
    refetch,
    isFetching,
  } = useListCommentRepliesQuery(commentId)

  const [reply, setReply] = useState('')
  const [isAnonymous, setIsAnonymous] = useState<boolean>(false)
  const [addCommentReply, { isLoading: isAddCommentReplyLoading }] = useAddCommentReplyMutation()
  console.log('postId', postId)
  const handleSpeechToTextResponse = (value: string) => {
    setReply(value)
  }

  const handleAddCommentReply = () => {
    if (!reply) return

    const formData = new FormData()
    formData.append('text', reply)
    formData.append('comment_type', chatOrCommentMessageType.text)
    addReplyApi(formData)
  }

  const handleAddVoiceMemo = (file: File, duration: number) => {
    if (file) {
      const formData = new FormData()
      formData.append('audio', file)
      formData.append('comment_type', chatOrCommentMessageType.audio)
      formData.append('audio_duration', duration.toString())
      addReplyApi(formData)
    }
  }

  const handleSendImage = (file: File) => {
    if (file) {
      const formData = new FormData()
      formData.append('image', file)
      formData.append('comment_type', chatOrCommentMessageType.image)
      addReplyApi(formData)
    }
  }

  const addReplyApi = (formData: FormData) => {
    formData.append('is_anonymous', isAnonymous ? 'true' : 'false')
    addCommentReply({
      commentId: commentId,
      payload: formData,
    })
      .unwrap()
      .then((res) => {
        setReply('')
        refetch()
        refetchReplies()
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  if (isFetching) {
    dispatch(startPageLoader())
  } else {
    dispatch(stopPageLoader())
  }

  return (
    <Box>
      <Box>
        {isFetchingReplies ? (
          <Box textAlign='center'>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            {commentReplies?.comment_replies.map((reply) => {
              return (
                <CommentItem
                  key={reply.id}
                  comment={reply}
                  dontShowReply
                  refetch={refetch}
                  refetchReplies={refetchReplies}
                />
              )
            })}
          </Box>
        )}
      </Box>

      <Box>
      {(role === userRoles.healthCare && !isUserUnAuthorized) && (
          <CommentBox
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setReply(e.target.value)}
            isLoading={isAddCommentReplyLoading}
            handleAddComment={handleAddCommentReply}
            handleAddVoiceMemo={handleAddVoiceMemo}
            comment={reply}
            placeholder='Add a reply'
            onSpeechToTextResponse={handleSpeechToTextResponse}
            handleImageUpload={handleSendImage}
            isAnonymous={isAnonymous}
            handleIsAnonymous={(event: React.ChangeEvent<HTMLInputElement>) =>
              setIsAnonymous(event.target.checked)
            }
          />
        )}
      </Box>
    </Box>
  )
}

export default ReplyContainer
