import React from 'react'
import { Outlet, useLocation, Navigate } from 'react-router-dom'
import { Box, CssBaseline } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import Sidebar from './Sidebar'
import { drawerWidth, routeUrls, userRoles } from 'src/utils/constants'
import PageLoader from './PageLoader'
import authorizationCheckHook from 'src/utils/setup/authorizationCheckHook'

const HomeLayout = () => {
  const { isAuthenticated, userInfo, token, role } = useSelector((state: RootState) => state.auth)
  const location = useLocation()
  const { isUnAuthorizedUser } = authorizationCheckHook()

  if (isAuthenticated === false || !userInfo || !token) {
    return <Navigate to={routeUrls.login} state={{ from: location }} />
  }

  if (role === userRoles.healthCare) {
    return <Navigate to={routeUrls.home} state={{ from: location }} />
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {!isUnAuthorizedUser() && <Sidebar />}

      <Box
        component='main'
        sx={{
          marginTop: '65px',
          flexGrow: 1,
          p: 3,
          width: { md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Outlet />
      </Box>

      <PageLoader />
    </Box>
  )
}

export default HomeLayout
