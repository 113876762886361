import { useLocation } from 'react-router-dom'

const authorizationCheckHook = () => {
  const location = useLocation()

  const isUnAuthorizedUser = () => {
    return location.pathname.includes('/KMNPQRSTVWXYZJHD-sap/')
  }

  return {
    isUnAuthorizedUser,
  }
}

export default authorizationCheckHook
