import React, { useEffect } from 'react'
import Header from 'src/Layout/LoginLayout/Header'
import { Box, Typography, Grid } from '@mui/material'
import styles from '../index.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { getTermAndConditionContent } from 'src/store/features/staticPageContent/staicPageContentAction'

const TermAndCondition = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { loading, termAndConditionData } = useSelector(
    (state: RootState) => state.staticPageContent,
  )

  useEffect(() => {
    dispatch(getTermAndConditionContent())
  }, [])

  return (
    <Box>
      <Header hideLogin showBackButton />

      <Box mt={0}>
        <Grid container>
          <Grid xs={12} sm={12} md={0}>
            {''}
          </Grid>
          <Grid xs={12} sm={12} md={12}>
            {/* <Typography textAlign='center' variant='h6'>
              Terms and Conditions
            </Typography> */}
            <Box
              mt={0}
              p={5}
              className={`${styles.createAccountFormWrapper} ${styles.termsAndConditionsFont}`}
              sx={{
                lineHeight: '25px',
                color: 'white',
              }}
            >
              {loading ? (
                'Loading...'
              ) : (
                <div dangerouslySetInnerHTML={{ __html: termAndConditionData }}></div>
                // <Typography variant='subtitle2' dangerouslySetInnerHTML={{ __html: termAndConditionData }} />
              )}
            </Box>
            <Box mt={5}></Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default TermAndCondition
