import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  DialogActions,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { routeUrls } from 'src/utils/constants'

// eslint-disable-next-line react/prop-types
export default function AuthModal({ isOpen }) {
  const navigate = useNavigate()

  const handleSignUp = () => {
    return navigate(routeUrls.healthcareUserRegistration, { replace: true })
  }

  const handleLogin = () => {
    return navigate(routeUrls.landingPage, { replace: true })
  }

  return (
    <Dialog open={isOpen} onOpenChange={() => null} modal={true}>
      <DialogContent onPointerDownOutside={(e) => e.preventDefault()}>
        <DialogTitle>
          <Typography variant={'h3'}>Sapi3nt Health</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant={'h5'}>
              Your comment was added, to get more engagement and connect with other health care professionals please sign up. If you already have an account, please log in.
            </Typography>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleSignUp}>Sign Up</Button>
          <Button onClick={handleLogin}>Log In</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
