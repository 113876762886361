/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Typography,
  Box,
} from '@mui/material';
import { SubSpeciality } from 'src/api/createFeed/types';
import { Specialty } from 'src/api/feed/types';

type SpecialityModalProps = {
  open: boolean;
  onClose: () => void;
  onApply: (selectedSpecialities: Specialty[], sortBy: string) => void;
  specialities: Specialty[];
  appliedSpecialty: Specialty[];
  appliedSortBy: string;
};

const SpecialityModal: React.FC<SpecialityModalProps> = ({
  open,
  onClose,
  onApply,
  specialities,
  appliedSpecialty,
  appliedSortBy,
}) => {
  const [selectedSpecialities, setSelectedSpecialities] = useState<Specialty[]>([]);
  const [sortBy, setSortBy] = useState<string>('');

  useEffect(() => {
    if (open) {
      setSelectedSpecialities(appliedSpecialty);
      setSortBy(appliedSortBy);
    }
  }, [specialities, open]);

  const handleSpecialityChange = (speciality: Specialty) => {
    setSelectedSpecialities(prev => {
      const alreadySelected = prev.find(sp => sp.id === speciality.id);
      if (alreadySelected) {
        // Uncheck speciality and its sub-specialities
        return prev.filter(sp => sp.id !== speciality.id);
      } else {
        // Check speciality and clear its sub-specialities
        return [...prev, { ...speciality, sub_specialities: [] }];
      }
    });
  };

  const handleSubSpecialityChange = (specialityId: number, subSpeciality: SubSpeciality) => {
    setSelectedSpecialities(prev => {
      const updatedSpecialities = prev.map(sp => {
        if (sp.id === specialityId) {
          const alreadySelectedSub = sp.sub_specialities.find(sub => sub.id === subSpeciality.id);
          if (alreadySelectedSub) {
            return {
              ...sp,
              sub_specialities: sp.sub_specialities.filter(sub => sub.id !== subSpeciality.id),
            };
          } else {
            return {
              ...sp,
              sub_specialities: [...sp.sub_specialities, subSpeciality],
            };
          }
        }
        return sp;
      });
      return updatedSpecialities;
    });
  };

  const handleSortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSortBy((event.target as HTMLInputElement).value);
  };

  const handleReset = () => {
    setSelectedSpecialities([]);
    setSortBy('most_recent');
    onApply([], 'most_recent');
    onClose();
  };

  const handleApply = () => {
    onApply(selectedSpecialities, sortBy);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="h5">Filter By:</Typography>
      </DialogTitle>
      <DialogContent>
        <Box mb={2} sx={{ height: '300px', overflowY: 'auto' }}>
          {specialities.map(speciality => (
            <Box key={speciality.id} mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="success"
                    checked={selectedSpecialities.some(sp => sp.id === speciality.id)}
                    onChange={() => handleSpecialityChange(speciality)}
                  />
                }
                label={<Typography variant='subtitle1' sx={{ color: 'white', fontWeight:'600' }}>{speciality.name}</Typography>}
              />
              {speciality.have_sub_speciality && selectedSpecialities.some(sp => sp.id === speciality.id) && (
                <Box sx={{}} p={0} ml={2}>
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, 1fr)',
                      gap: 1,
                    }}
                  >
                    {speciality.sub_specialities.map(sub => (
                      <FormControlLabel
                        key={sub.id}
                        control={
                          <Checkbox
                            size="small"
                            color="success"
                            checked={
                              selectedSpecialities
                                .find(sp => sp.id === speciality.id)
                                ?.sub_specialities.some(subSp => subSp.id === sub.id) || false
                            }
                            onChange={() => handleSubSpecialityChange(speciality.id, sub)}
                          />
                        }
                        label={<Typography variant='subtitle2' sx={{ color: 'white', fontWeight:'400' }}>{sub.name}</Typography>}
                      />
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          ))}
        </Box>
        <Box>
          <Typography variant="h5">Sort By:</Typography>
          <FormControl component="fieldset">
            <RadioGroup value={sortBy} onChange={handleSortChange}>
              <FormControlLabel
                value="most_recent"
                control={<Radio />}
                label={<Typography variant='subtitle1' sx={{ color: 'white', fontWeight:'600'  }}>Most Recent</Typography>}
              />
              <FormControlLabel
                value="likes"
                control={<Radio />}
                label={<Typography variant='subtitle1' sx={{ color: 'white', fontWeight:'600'  }}>Likes</Typography>}
              />
              <FormControlLabel
                value="dislikes"
                control={<Radio />}
                label={<Typography variant='subtitle1' sx={{ color: 'white', fontWeight:'600'  }}>Dislikes</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset} sx={{ color: 'white' }}>
          Reset
        </Button>
        <Button onClick={onClose} sx={{ color: 'white' }}>
          Cancel
        </Button>
        <Button onClick={handleApply} color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SpecialityModal;
