import React, { useState } from 'react'
import { Box, Button, Typography, Grid, CircularProgress } from '@mui/material'
import Header from 'src/Layout/HomeLayout/Header'
import styles from './index.module.scss'
import SwitchButton from 'src/Component/SwitchButton'
import {
  useDeleteUserProfileMutation,
  useGetSettingsQuery,
  useSaveSettingsMutation,
} from 'src/api/userProfile'
import { errorToast } from 'src/utils/toastService'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { routeUrls, userRoles } from 'src/utils/constants'
import CustomizedDialogBox from 'src/Component/CustomizedDialogBox'
import { Navigate, useNavigate } from 'react-router-dom'

const Settings = () => {
  const { data, isFetching, refetch } = useGetSettingsQuery()
  const [saveSettings, { isLoading }] = useSaveSettingsMutation()
  const { role } = useSelector((state: RootState) => state.auth)
  const [open, setOpen] = useState<boolean>(false)
  const navigate = useNavigate()

  const [deleteAccountAPI, { isLoading: isDeleting }] = useDeleteUserProfileMutation()

  const onDeleteConfirm = () => {
    deleteAccountAPI()
      .unwrap()
      .then((res) => {
        if (role === 'healthcare') {
          navigate(routeUrls.home)
        } else {
          navigate(routeUrls.nonHealthCareUser.home)
        }

        console.log(res)
      })

    setOpen(false)
  }

  const handleOpenDeleteConfirmation = () => {
    setOpen(true)
  }
  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTimeout(() => {
      if (data) {
        console.log(e)
        saveSettings({
          ...data,
          [e.target.name]: e.target.checked,
        })
          .unwrap()
          .then((res) => {
            refetch()
          })
          .catch((err: any) => {
            console.log(err)
            const obj = err.data.data
            for (const key in obj) {
              if (Object.prototype.hasOwnProperty.call(obj, key)) {
                const element = obj[key]
                if (typeof element === 'object' && element.length) {
                  errorToast(`${key}: ` + element.toString(','))
                }
              }
            }
          })
      }
    }, 350)
  }
  return (
    <Box>
      <Header>
        <Box width='100%' p={3} pb={0}>
          <Box mt={1}>
            <Typography variant='subtitle1' fontSize='24px'>
              Settings
            </Typography>
          </Box>
        </Box>
      </Header>

      <Box>
        <Grid justifyContent='center' container>
      
            <Box className={styles.formWrapper}>
              {isFetching || isLoading ? (
                <Grid container alignItems='center' justifyContent='center' height='100%'>
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                </Grid>
              ) : (
                <>
                  {role === userRoles.healthCare && (
                    <Grid container justifyContent='space-between' alignItems='center' p={4}>
                      <Typography variant='subtitle1'>Make Profile Public</Typography>
                      <SwitchButton
                        name='is_public_profile'
                        isControlled={false}
                        defaultChecked={!!data?.is_public_profile}
                        onChange={handleSwitchChange}
                      />
                    </Grid>
                  )}

                  <Grid container justifyContent='space-between' alignItems='center' p={4}>
                    <Typography variant='subtitle1'>In app Notification</Typography>
                    <SwitchButton
                      disabled
                      name='inapp'
                      isControlled={false}
                      defaultChecked={!!data?.inapp}
                      onChange={handleSwitchChange}
                    />
                  </Grid>

                  <Grid container justifyContent='space-between' alignItems='center' p={4}>
                    <Typography variant='subtitle1'>Push Notifications</Typography>
                    <SwitchButton
                      disabled
                      name='push'
                      isControlled={false}
                      defaultChecked={!!data?.push}
                      onChange={handleSwitchChange}
                    />
                  </Grid>

                  <Grid container justifyContent='space-between' alignItems='center' p={4}>
                    <Typography variant='subtitle1'>Email Notifications</Typography>
                    <SwitchButton
                      disabled
                      name='email'
                      isControlled={false}
                      defaultChecked={!!data?.email}
                      onChange={handleSwitchChange}
                    />
                  </Grid>
                  <Grid container justifyContent='center' alignItems='center' p={4}>
                    <Button
                      onClick={handleOpenDeleteConfirmation}
                      type='button'
                      variant='contained'
                      color='error'
                      // fullWidth
                      disabled={isLoading}
                      className={isLoading ? 'buttonDisabled' : ''}
                    >
                      {isLoading ? <CircularProgress size={25} /> : 'Delete Account'}
                    </Button>
                  </Grid>

                  <CustomizedDialogBox open={open} onClose={() => setOpen(false)}>
                    <Box>
                      <Typography variant='h3' textAlign='center' mb={5}>
                        {' '}
                        Are you sure to delete your Account?
                      </Typography>
                      <Grid container>
                        <Grid xs={6} p={2}>
                          <Button
                            type='button'
                            onClick={onDeleteConfirm}
                            variant='contained'
                            color='success'
                            fullWidth
                            disabled={isLoading}
                            className={isLoading ? 'buttonDisabled' : ''}
                          >
                            {isLoading ? <CircularProgress size={25} /> : 'Confirm'}
                          </Button>
                        </Grid>
                        <Grid xs={6} p={2}>
                          <Button
                            type='button'
                            onClick={() => setOpen(false)}
                            variant='contained'
                            color='error'
                            fullWidth
                          >
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </CustomizedDialogBox>
                </>
              )}
            </Box>
      
        </Grid>
      </Box>
    </Box>
  )
}

export default Settings
