import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import { Box, Typography } from '@mui/material';
import CustomItem from './CustomItem';
import styles from './index.module.scss';
import { errorToast } from 'src/utils/toastService';

interface AutoCompleteInputProps {
  data: Array<any>;
  setSelectedValue(newData: Array<any>): any;
  selectedValue: Array<any>;
  onType(query: string): any;
  createOwnEnabled?: boolean;
  placeholder?: string;
  isMultiple?: boolean;
  name?: string;
}

/* eslint-disable react/prop-types */
const AutoCompleteInput: React.FC<AutoCompleteInputProps> = forwardRef(
  (
    { data, setSelectedValue, selectedValue, onType, createOwnEnabled, placeholder, isMultiple = true, name },
    forwardedRef,
  ) => {
    const [queryState, setQueryState] = useState('');
    const localRef = useRef(null);
    const ref = forwardedRef || localRef;
    const [filteredSuggestions, setFilteredSuggestions] = useState(data);

    const [isVisible, toggleVisible] = useState(false);

    useEffect(() => {
      if (!isMultiple) {
        setQueryState(selectedValue[0]?.label ?? '');
      }
    }, [selectedValue, isMultiple]);

    useEffect(() => {
      setFilteredSuggestions(data);
    }, [data]);

    useEffect(() => {
      return () => {
        if (pendingIntervalId) clearTimeout(pendingIntervalId);
      };
    }, []);

    let pendingIntervalId;
    const debouncedSearchHandler = query => {
      if (pendingIntervalId) {
        clearTimeout(pendingIntervalId);
      }
      pendingIntervalId = setTimeout(() => {
        onType(query);
      }, 500);
    };

    const onCheckHandler = useCallback(
      item => {
        if (!isMultiple) {
          setSelectedValue([item]);
          setQueryState(item.label);
          toggleVisible(false);
          return;
        }

        if (item.haveSubspecialty) return;

        let isSelected = false;
        const newSelectedValues = selectedValue.reduce((acc, storedIndex) => {
          if (storedIndex.value === item.value) {
            isSelected = true;
          } else {
            acc.push(storedIndex);
          }
          return acc;
        }, []);

        if (!isSelected && selectedValue.length >= 8) {
          errorToast('Warning: Maximum 8 selections are allowed')
          return;
        }

        if (!isSelected) {
          newSelectedValues.push(item);
        }

        setSelectedValue(newSelectedValues);
      },
      [selectedValue, setSelectedValue],
    );

    const renderCreateOwnRow = () => {
      return (
        <div
          className={styles.itemContainer}
          onClick={() => {
            // dispatch(createTag({ name: queryState }));
          }}
        >
          <Typography variant="subtitle1" fontWeight={600}>
            Create your own
          </Typography>
        </div>
      );
    };

    const parentRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
      if (parentRef.current && !parentRef.current.contains(event.target as Node)) {
        toggleVisible(false);
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    return (
      <div ref={parentRef} style={{ position: 'relative' }}>
        <input
          onFocus={() => toggleVisible(true)}
          onChange={e => {
            const query = e.target.value;
            debouncedSearchHandler(query);
            setQueryState(query);
          }}
          className={styles.dropDownContainer}
          placeholder={placeholder}
          value={queryState}
        />

        {isVisible && (
          <Box className={styles.suggestionBox}>
            {filteredSuggestions.map(item => (
              <Box key={item.value}>
                <CustomItem
                  item={item}
                  isMultiple={isMultiple}
                  onParentClick={onCheckHandler}
                  selectedValue={selectedValue}
                  setter={setSelectedValue}
                />
              </Box>
            ))}
            {createOwnEnabled && renderCreateOwnRow()}
          </Box>
        )}
      </div>
    );
  },
);

AutoCompleteInput.displayName = 'AutoCompleteInput';
export default AutoCompleteInput;
