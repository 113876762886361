import React, { useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Box } from '@mui/material';

interface ExpandablePostDescriptionProps {
  text: string;
  maxLength: number;
  onHeightChange?: (height: number) => void;
  initiallyOpen?: boolean;
}

const ExpandablePostDescription: React.FC<ExpandablePostDescriptionProps> = ({ text, maxLength, onHeightChange, initiallyOpen }) => {
  const [isExpanded, setIsExpanded] = useState(initiallyOpen??false);
  const descriptionRef = useRef<HTMLDivElement>(null);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (descriptionRef.current) {
      onHeightChange?.(descriptionRef.current.offsetHeight);
    }
  }, [isExpanded, onHeightChange]);

  const displayText = isExpanded ? text : text?.length > maxLength ? text.slice(0, maxLength) + '...' : text;

  return (
    <Box ref={descriptionRef}>
      <Typography variant="subtitle2" mt={1} sx={{ whiteSpace: 'pre-wrap' }}>
        {displayText}
        {text?.length > maxLength && (
          <Box component="span" display="block" sx={{zIndex:99}}>
            <Link
              component="button"
              variant="subtitle1"
              onClick={toggleReadMore}
              sx={{ marginLeft: '0px', textDecoration: 'none' }}
            >
              {isExpanded ? 'Read Less' : 'Read More'}
            </Link>
          </Box>
        )}
      </Typography>
    </Box>
  );
};

export default ExpandablePostDescription;
