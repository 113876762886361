import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Typography } from '@mui/material';
import axios, { CancelTokenSource } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useLazyGetAzureSignedUrlQuery } from 'src/api/createFeed';
import { errorToast, successToast } from 'src/utils/toastService';

interface VideoUploadProps {
  video: {
    id: number;
    shortVideoFileName: string;
    videoBinaryData: Buffer | string;
    isUploaded: boolean;
    status: string;
  };
  onUploadComplete: (videoName: string) => void;
  editMode?: boolean;
  onVideoDelete: (videoId: number) => void;
}

const VideoUpload: React.FC<VideoUploadProps> = ({ video, onUploadComplete, editMode, onVideoDelete }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploadComplete, setIsUploadComplete] = useState(video.isUploaded);
  const [uploadSource, setUploadSource] = useState<CancelTokenSource | null>(null);

  const hasEffectRun = useRef(false);

  const [getAzureSignedUrl, { data, error, isLoading }] = useLazyGetAzureSignedUrlQuery();

  const cancelUpload = () => {
    if (uploadSource) {
      uploadSource.cancel('Upload cancelled by user.');
    }
  };

  useEffect(() => {
   
    console.log('VIDEO_UPLOAD::', video, isUploadComplete, uploadProgress);
    if (isUploadComplete || uploadProgress > 0) return;

    const getUploadConfig = () => {
      const source = axios.CancelToken.source();
      setUploadSource(source);

      return {
        onUploadProgress: (progressEvent: any) => {
          let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if (percentCompleted === 100) {
            percentCompleted = 99;
            setIsUploadComplete(false);
          }
          setUploadProgress(percentCompleted);
        },
        cancelToken: source.token,
      };
    };

    const uploadToAzure = async (url: string, config: any) => {
     
      return await axios({
        method: 'PUT',
        url: url,
        data: video.videoBinaryData,
        // timeout: 600000,
        headers: {
          'Content-Type': 'video/mp4',
          'x-ms-blob-type': 'BlockBlob',
        },
        onUploadProgress: config.onUploadProgress,
        cancelToken: config.cancelToken,
      });
    };

    const uploadVideo = async () => {
      if (isUploadComplete) {
        console.info('UPLOAD_PROGRESS::Video already uploaded');
        return;
      }
      if (video?.videoBinaryData) {
        const config = getUploadConfig();

        const { data: azureSignedUrl } = await getAzureSignedUrl({
          // eslint-disable-next-line camelcase
          file_name: video.shortVideoFileName,
        });

        if (!azureSignedUrl?.url) throw new Error('Error in getting signed url');
        console.log('AZURE_SIGNED_URL_RECEIVED::', azureSignedUrl.url);

        try {
          if (hasEffectRun.current)  return ;
          hasEffectRun.current = true;
          const response = await uploadToAzure(azureSignedUrl.url, config);
          console.log('AZURE_RESPONSE_STATUS', JSON.stringify(response.status));
          setIsUploadComplete(true);

          onUploadComplete(video.shortVideoFileName);
          successToast('Video uploaded successfully.');
        } catch (thrown: any) {
          if (axios.isCancel(thrown)) {
            console.log('Request canceled', thrown.message);
          } else {
            console.error('Error in uploading video', thrown);
            errorToast('Video upload failed. Please try again.');
          }
        }
      }
    };

    if (!video.videoBinaryData) {
      setIsUploadComplete(true);
    } else {
      uploadVideo();
    }
  }, [video]);

  const handleDelete = () => {
    cancelUpload();
    onVideoDelete(video.id);
    console.log('Delete icon clicked', video.id);
  };

  const getVideoSource = (data: Buffer | string) => {
    const videoSrc = typeof data === 'string' ? data : URL.createObjectURL(new Blob([data], { type: 'video/mp4' }));
    return videoSrc;
  };

  return (
    <Box
      sx={{
        border: '0px solid red',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {isUploadComplete ? (
        <>
          <video controls style={{ objectFit: 'contain', height: '100%', borderRadius: '10px' }}>
            <source src={getVideoSource(video.videoBinaryData)} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <Box position="absolute" bottom={0} right={0} p={1}>
            <Button
              style={{
                color: 'white',
                textAlign: 'center',
                border: '2px solid white',
                borderRadius: '50%',
                padding: '10px',
                minWidth: 'auto',
              }}
              onClick={handleDelete}
            >
              <DeleteIcon />
            </Button>
          </Box>
        </>
      ) : (
        <>
          <video muted style={{ width: '100%', height: '100%', borderRadius: '10px' }}>
            <source
              src={URL.createObjectURL(new Blob([video.videoBinaryData], { type: 'video/mp4' }))}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <Box
            height={'100%'}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="rgba(0, 0, 0, 0.5)"
            flexDirection={'column'}
            width={'50%'}
          >
            <Box width="40%" bgcolor="#e0e0e0" borderRadius="5px" overflow="hidden">
              <Box
                width={`${uploadProgress}%`}
                height="10px"
                bgcolor={'green'}
                sx={{
                  transition: 'width 0.3s ease-in-out',
                }}
              />
            </Box>
            <Typography mt={1} variant="caption" color="white" textAlign="center">
              Uploading...
            </Typography>
            <Box position="absolute" bottom={0} right={0} p={1}>
              <Button
                style={{
                  color: 'white',
                  textAlign: 'center',
                  border: '2px solid white',
                  borderRadius: '50%',
                  padding: '10px',
                  minWidth: 'auto',
                }}
                onClick={handleDelete}
              >
                <DeleteIcon />
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );


};

export default React.memo(VideoUpload);
