import React, { useState } from 'react'
import { Box, CircularProgress, Grid, Avatar, Typography, IconButton, Popover } from '@mui/material'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import styles from './index.module.scss'
import LikeIcon from 'src/assets/icons/thumbs-up.png'
import ActiveLikeIcon from 'src/assets/icons/thumbs-up-active.png'
import ThinkingIcon from 'src/assets/icons/thinking.png'
import { CommentI } from 'src/api/feed/types'
import { useDeleteMyCommentMutation, useToggleCommentLikeMutation } from 'src/api/feed'
import ReplyContainer from './ReplyContainer'
import AudioPlayer from 'src/Component/AudioPlayer'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import { downloadFileFromUrl } from 'src/utils/helpers'
import DeleteIcon from '@mui/icons-material/Delete'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { routeUrls, userRoles } from 'src/utils/constants'
import { useNavigate } from 'react-router-dom'
import authorizationCheckHook from 'src/utils/setup/authorizationCheckHook'

dayjs.extend(relativeTime)

type CommentItemProps = {
  comment: CommentI
  children?: JSX.Element
  dontShowReply?: boolean
  refetch: () => void
  refetchReplies?: () => void
}
const CommentItem = ({
  comment,
  dontShowReply = false,
  refetch,
  refetchReplies,
}: CommentItemProps) => {
  const navigate = useNavigate()
  const { isUnAuthorizedUser } = authorizationCheckHook()
  const isUserUnAuthorized = isUnAuthorizedUser()
  const [replyBoxToggle, setReplyBoxToggle] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [deleteShow, setDeleteShow] = useState(false)
  const { role, userInfo } = useSelector((state: RootState) => state.auth)
  const toggleReplyBox = () => {
    setReplyBoxToggle((prevState) => !prevState)
  }
  const [commentDelete, { isLoading: isDeleteLoading }] = useDeleteMyCommentMutation()
  const [toggleLike, { isLoading: isLikeLoading }] = useToggleCommentLikeMutation()

  const handleLikeToggle = () => {
    toggleLike(comment.id)
      .unwrap()
      .then((res) => {
        console.log(res)
        if (typeof refetch === 'function') {
          refetch()
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  const downloadFile = async () => {
    if (comment.comment_type === 'image' && comment.image) {
      setIsDownloading(true)
      const imageName = comment.image.split('/')
      downloadFileFromUrl(
        comment.image,
        imageName.length ? imageName[imageName.length - 1] : 'image.jpg',
        true,
      )
      setIsDownloading(false)
    }
  }

  const renderComment = () => {
    switch (comment.comment_type) {
      case 'text':
        return !!comment.text && <Typography variant={'subtitle2'}>{comment.text}</Typography>

      case 'audio':
        return (
          !!comment.audio && (
            <Box width={350}>
              <AudioPlayer
                url={comment.audio}
                durationInSec={Number(comment.audio_duration) / 1000}
              />
            </Box>
          )
        )

      case 'image':
        return (
          !!comment.image && (
            <Box sx={{ position: 'relative', width: 350 }}>
              <img src={comment.image} width={350} />
              <IconButton
                onClick={downloadFile}
                color='success'
                sx={{
                  position: 'absolute',
                  bottom: 5,
                  right: 0,
                  background: 'black',
                  margin: 1,
                  ':hover': {
                    background: 'black',
                  },
                }}
                disabled={isDownloading}
              >
                <DownloadForOfflineIcon />
              </IconButton>
            </Box>
          )
        )

      default:
        return null
    }
  }

  const handleDeleteComment = () => {
    console.log('handleDeleteComment', deleteShow)
    setDeleteShow(!deleteShow)
  }

  const onDeleteComment = () => {
    commentDelete(comment?.id).then(() => {
      refetch()
      if (refetchReplies) {
        refetchReplies()
      }
      setDeleteShow(false)
    })
  }

  const redirectToViewProfile = () => {
    if (role === userRoles.healthCare) {
      navigate(routeUrls.viewProfile.replace(':userId', comment?.user?.id.toString()))
    }
    if (role === userRoles.nonHealthCare) {
      navigate(
        routeUrls.nonHealthCareUser.viewOthersProfile.replace(
          ':userId',
          comment?.user?.id.toString(),
        ),
      )
    }
  }

  return (
    <Box key={comment.id} mt={2}>
      <Box>
        <Grid container>
          <Grid xs={1} sx={{ cursor: 'pointer' }} onClick={!isUserUnAuthorized ? redirectToViewProfile: null}>
            <Avatar
              alt={comment.user.name}
              src={comment.user.profile_picture || 'broken-image.png'}
              className={styles.feedAvatarSmall}
            />
          </Grid>
          <Grid xs={11}>
            <Box>
              <Box>
                <Grid>
                  {userInfo?.id == comment?.user?.id && !isUserUnAuthorized && (
                    <Typography
                      variant={'subtitle2'}
                      fontSize='0.7rem'
                      display={'flex'}
                      mb={-2.5}
                      justifyContent={'flex-end'}
                      sx={{ cursor: 'pointer' }}
                    >
                      <MoreVertIcon onClick={handleDeleteComment} />
                    </Typography>
                  )}

                  <Typography
                    variant={'subtitle1'}
                    sx={{ cursor: 'pointer' }}
                    onClick={!isUserUnAuthorized ? redirectToViewProfile: null}
                  >
                    {comment.user.name}
                  </Typography>
                </Grid>

                {deleteShow ? (
                  <Typography
                    variant={'subtitle2'}
                    fontSize='0.7rem'
                    display={'flex'}
                    // mb={-2.5}
                    justifyContent={'flex-end'}
                    // onClick={}
                  >
                    {/* <MoreVertIcon /> */}
                    <Typography
                      // alignItems='center'
                      sx={{ pr: 2, display: 'flex', cursor: 'pointer' }}
                      color='red'
                      onClick={onDeleteComment}
                    >
                      <DeleteIcon /> &nbsp; Delete Comment
                    </Typography>
                  </Typography>
                ) : null}
                <Typography variant='subtitle2' fontSize='0.7rem'>
                  {dayjs(comment.created_at).fromNow()}
                </Typography>
              </Box>

              <Box>
                {renderComment()}

                <Box>
                  <Grid container>
                    <Grid item>
                      <Grid
                        container
                        alignItems='end'
                        sx={{ cursor: isLikeLoading ? 'not-allowed' : 'pointer' }}
                        onClick={!isUserUnAuthorized ? handleLikeToggle : null}
                      >
                        <Grid item>
                          <Typography variant='subtitle2' fontSize='0.7rem'>
                            {comment.like_count} Likes
                          </Typography>
                        </Grid>

                        <Grid item>
                          <IconButton
                            sx={{
                              padding: 0,
                              paddingLeft: '2px',
                            }}
                          >
                            {isLikeLoading ? (
                              <CircularProgress size={15} />
                            ) : (
                              <img
                                src={comment.is_reacted ? ActiveLikeIcon : LikeIcon}
                                height={20}
                              />
                            )}
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>

                    {!dontShowReply && (
                      <Grid item>
                        <Grid
                          container
                          alignItems='end'
                          sx={{ cursor: 'pointer' }}
                          onClick={toggleReplyBox}
                        >
                          <Grid item>
                            <Typography pl={3} variant='subtitle2' fontSize='0.7rem'>
                              {comment.reply_count} Replies
                            </Typography>
                          </Grid>

                          <Grid item>
                            <IconButton
                              sx={{
                                padding: 0,
                              }}
                            >
                              <img src={ThinkingIcon} height={20} />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Box>
            </Box>

            {replyBoxToggle && <ReplyContainer commentId={comment.id} postId={comment.post} />}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default CommentItem
