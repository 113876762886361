import React from 'react'
import AppBar from '@mui/material/AppBar'

import IconButton from '@mui/material/IconButton'

import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import { drawerWidth } from 'src/utils/constants'
import { useDispatch } from 'react-redux'
import { handleDrawerToggle } from 'src/store/features/common/commonSlice'
import authorizationCheckHook from 'src/utils/setup/authorizationCheckHook'

interface HeaderProps {
  children?: React.ReactElement[] | React.ReactElement
}

const Header = ({ children }: HeaderProps) => {
  const dispatch = useDispatch()

  const { isUnAuthorizedUser } = authorizationCheckHook()

  const isUserUnAuthorized = isUnAuthorizedUser()

  const renderMenuIcon = () => {
    return Array.isArray(children) ? (
      <>
        <Toolbar
          sx={{
            alignItems: 'baseline',
          }}
        >
          {!isUserUnAuthorized && (
            <IconButton
              aria-label='open drawer'
              edge='start'
              onClick={() => dispatch(handleDrawerToggle())}
              sx={{ mr: 2, display: { md: 'none' }, color: 'white' }}
            >
              <MenuIcon />
            </IconButton>
          )}
          {children && children[0]}
        </Toolbar>
        {children[1]}
      </>
    ) : (
      <Toolbar
        sx={{
          alignItems: 'baseline',
        }}
      >
        {!isUserUnAuthorized && (
          <IconButton
            aria-label='open drawer'
            edge='start'
            onClick={() => dispatch(handleDrawerToggle())}
            sx={{ mr: 2, display: { md: 'none' }, color: 'white' }}
          >
            <MenuIcon />
          </IconButton>
        )}
        {children}
      </Toolbar>
    )
  }

  return (
    <AppBar
      className='appHeader'
      position='fixed'
      sx={{
        width: { md: isUserUnAuthorized ? '100%' : `calc(100% - ${drawerWidth}px)` },
        ml: { md: `${drawerWidth}px` },
      }}
    >
      {renderMenuIcon()}
    </AppBar>
  )
}

export default Header
